import React, { Component } from 'react'

class CategoryImage extends Component {
    render () {
        const { categoryName } = this.props
        const categoryNameWithExtension = categoryName + '.jpg'

        return (
            <div className="main-img" style={{ width: '100%', height: '400px', backgroundSize: 'cover', backgroundPosition: 'center center', backgroundImage: `url('https://pelprint.pelhughes.com/ustorethemes/PelPrint/assets/images/${categoryNameWithExtension}')` }} />
        )
    }
}

export default CategoryImage
